import XRegExp from 'xregexp'

/**
 * CargoWidget class component
 */
class CargoWidget {
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for CargoWidget class')
            return
        }

        const { url } = this.container.data()
        this.submitUrl = url

        // Attach event listeners
        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click touch', '.btn-search', (event) => {
            event.preventDefault()
            const data = {}
            let validInputs = true

            this.container.find('input').each((_, input) => {
                const $input = $(input)
                const inputName = $input.data('input')
                const value = $input.val()
                data[inputName] = value
                if (!$input.val().trim() && $input.prop('required')) {
                    const { requiredMessage } = $input.data()
                    $input.addClass('is-invalid')
                    $input.parents('.widget-input-group').find('.invalid-feedback').html('').addClass('d-block').html(requiredMessage)
                    validInputs = false
                }
            })


            if (validInputs) {
                let url
                if (this.submitUrl) {
                    url = new URL(location.origin + this.submitUrl)
                } else {
                    url = new URL(location.origin + location.pathname)
                }
                url.search = new URLSearchParams(data)
                location.href = url.href
            } else {
                this.container.find('input').addClass('is-invalid')
            }
        })

        this.container.on('input', '.prefix-input-container input', () => {
            this.container.find('.prefix-input').removeClass('default')
        })

        this.container.on('focus', 'input', (event) => {
            const isPrefixInput = $(event.currentTarget).hasClass('prefix-input')
            const className = isPrefixInput ? 'border-prefix' : 'border-main'

            this.container.find('.widget-input-group').addClass(className)
        })

        this.container.on('blur', 'input', (event) => {
            const isPrefixInput = $(event.currentTarget).hasClass('prefix-input')
            const className = isPrefixInput ? 'border-prefix' : 'border-main'

            this.container.find('.widget-input-group').removeClass(className)
        })

        this.container.on('keydown', 'input', (event) => {
            const $input = $(event.currentTarget)
            this.container.find('input').removeClass('is-invalid')
            $input.parents('.widget-input-group').find('.invalid-feedback').removeClass('d-block').html('')
        })

        // Used for detecting pasting to field
        let ctrlDown = false
        let ctrlKey = 17
        let cmdKey = 91
        let vKey = 86

        this.container.on('keydown', '.allow-paste', event => {
            if (event.keyCode === ctrlKey || event.keyCode === cmdKey) ctrlDown = true
        })

        this.container.on('keyup', '.allow-paste', event => {
            if (event.keyCode === ctrlKey || event.keyCode === cmdKey) ctrlDown = false
        })

        // Numbers only
        this.container.on('keydown', '.numbers-only', event => {
            const $input = $(event.currentTarget)

            // Enable enter
            if (event.keyCode === 13) {
                return true
            }

            // Allow pasting
            if ($input.hasClass('allow-paste')) {
                if (ctrlDown && event.keyCode === vKey) {
                    return true
                }
            }

            // Enable Backspace
            if (event.keyCode === 8) {
                return true
            }

            const regex = XRegExp("[0-9]+$")
            return regex.test(event.key)
        })
    }

    /**
     * Remove event listeners
     */
    removeEventListeners() {
        this.container.off('click touch')
        this.container.off('keydown')
    }
}

export default CargoWidget